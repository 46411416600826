import * as s from './Ar404.module.scss'

import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import SvgArrow from 'src/assets/svg/arrow-right-2.svg'
import { TAr404Props } from './Ar404.types'

export const Ar404: React.FC<TAr404Props> = () => {
  return (
    <div className={s.container}>
      <h1>404</h1>
      <p>
        Sorry, the page you are looking for cannot be found.
        <br />
        If this issue persists, please <Link to={ROUTES.contact()}>contact us.</Link>
        <br />
        <Link to={ROUTES.homepage()} className={s.homeBack}>
          <SvgArrow />
          Home
        </Link>
      </p>
    </div>
  )
}
