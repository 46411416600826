import * as React from 'react'

import { Ar404 } from 'src/components/Ar404'
import { ArContainer } from 'src/components/ArContainer'

const NotFoundPage = () => {
  return (
    <ArContainer>
      <Ar404 />
    </ArContainer>
  )
}

export default NotFoundPage
